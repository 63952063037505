@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/archivo/Archivo-Regular.woff2") format('woff2'),
     url("../fonts/archivo/Archivo-Regular.woff") format('woff');
}

@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/archivo/Archivo-Medium.woff2") format('woff2'),
    url("../fonts/archivo/Archivo-Medium.woff") format('woff');
}

@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/archivo/Archivo-Bold.woff2") format('woff2'),
    url("../fonts/archivo/Archivo-Bold.woff") format('woff');
}

@font-face {
    font-family: 'Archivo SemiExpanded';
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/archivo/Archivo_SemiExpanded-Medium.woff2") format('woff2'),
    url("../fonts/archivo/Archivo_SemiExpanded-Medium.woff") format('woff');
}

@font-face {
    font-family: 'Archivo SemiExpanded';
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/archivo/Archivo_SemiExpanded-SemiBold.woff2") format('woff2'),
    url("../fonts/archivo/Archivo_SemiExpanded-SemiBold.woff") format('woff');
}

@font-face {
    font-family: 'Archivo SemiExpanded';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/archivo/Archivo_SemiExpanded-Bold.woff2") format('woff2'),
     url("../fonts/archivo/Archivo_SemiExpanded-Bold.woff") format('woff');
}