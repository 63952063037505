/* theme variables and custom utility classes */

:root {
    --body-light: #F9F9F9;
    --body-dark: #00193B;
    --widget-light: #FFFFFF;
    --widget-dark: #031123;
    --header-light: #00193B;
    --header-dark: #D8E9FF;
    --text-light: #515C6B;
    --text-dark: #E2E1E1;
    --accent-light: #035ECF;
    --accent-dark: #4F89FC;
    --highlight-dark: #273241;
    --input-border-light: #E2E1E1;
    --input-border-dark: #354585;
    --border-light: #F1F1F1;
    --border-dark: rgba(53, 69, 133, 0.4);
    --gray: #8D8D99;
    --label: #6E757F;
    --red: #FF5470;
    --green: #00BA9D;
    --yellow: #F8D518;
    --orange: #FF6900;
    --shadow-light: 0px 3px 14px rgba(226, 225, 225, 0.75);
    --shadow-dark: 0px 3px 14px #00285D;
    --shadow-glass: inset 1px 1px 2px #FFFFFF, inset -2px -2px 2px #003A31;;
    --radial-blue: radial-gradient(59.32% 59.32% at 50% 31.83%, rgba(62, 128, 211, 0.77) 0%, rgba(1, 40, 90, 0.77) 100%), #00193B;
    --linear-sky: linear-gradient(44deg, rgba(190, 250, 241, 0.13) 0%, rgba(3, 94, 207, 0.20) 100%), #FFF;
    --linear-blue: linear-gradient(50.81deg, #00214D 6.78%, #3E80D3 65.48%, #01285A 101.42%);
    --linear-sm-light: linear-gradient(177deg, #00193B 0%, #035ECF 100%);
    --linear-sm-dark: linear-gradient(177deg, #5FA3FF 0%, #035ECF 100%);
    --glass: linear-gradient(134.73deg, rgba(1, 88, 83, 0.0001) 32.33%, rgba(0, 112, 117, 0.186708) 79.27%);
    --body-font: 'Archivo', sans-serif;
    --heading-font: 'Archivo SemiExpanded', sans-serif;
    --transition: .3s ease-in-out;
    --element-height: 44px;
    --app-height: 100%;
}

.bg-radial-blue {
    background: var(--radial-blue);
}

.bg-linear-blue {
    background: var(--linear-blue);
}

.bg-linear-sky {
    background: var(--linear-sky);
}